import Microfrontend from "../../pages/MicroFrontend.vue";

export default [
  {
    path: "/pwa-home",
    name: "pwa-home",
    meta: {
      isPublic: true,
      requiresAuth: false,
      title: "WebApp - Minha Claro Residencial",
      squad: "Home"
    },
    component: () =>
      import(
        /* webpackChunkName: "pwa-install" */
        "../../pages/Pwa.vue"
      )
  },
  {
    path: "/pwa-install",
    name: "pwa-install",
    meta: {
      isPublic: true,
      requiresAuth: false,
      title: "WebApp Instalação - Minha Claro Residencial",
      squad: "Global"
    },
    component: () =>
      import(
        /* webpackChunkName: "pwa-install" */
        "../../pages/PwaInstall.vue"
      )
  },
  {
    path: "/public",
    name: "Public",
    meta: {
      requiresAuth: false,
      isPublic: true,
      isEmptyLayout: true,
      squad: "Global",
      title: "Public - Minha Claro Residencial"
    },
    component: Microfrontend,
    props: {
      pageId: "public-area"
    }
  },
  {
    path: "/universal-links",
    name: "Universal Links",
    meta: {
      requiresAuth: false,
      isPublic: true,
      isEmptyLayout: true,
      squad: "Global",
      title: "Links Universais - Minha Claro Residencial"
    },
    component: Microfrontend,
    props: {
      pageId: "universal-links"
    }
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      requiresAuth: true,
      oAuth2: true,
      squad: "Global",
      title: "Login - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "migration-select-contract" */
        "../../pages/Login.vue"
      )
  },
  {
    path: "/selecao-de-contrato",
    name: "select-contract",
    meta: {
      requiresAuth: true,
      isMigratedPage: true,
      squad: "Home",
      title: "Seleção de Contrato - Minha Claro Residencial"
    },

    component: () =>
      import(
        /* webpackChunkName: "migration-select-contract" */
        "../../pages/SelectContract.vue"
      )
  },
  {
    path: "/redirect",
    name: "redirect",
    meta: {
      requiresAuth: true,
      squad: "Global",
      title: "Redirect - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "easy-invoice" */
        "../../pages/Redirect.vue"
      )
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      isPublic: true,
      squad: "Global",
      title: "Logout - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "logout" */
        "../../pages/Logout.vue"
      )
  },
  {
    path: "/manutencao",
    name: "maintenance",
    meta: {
      isMaintenance: true,
      squad: "Global",
      title: "Manutenção - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */
        "../../pages/Maintenance.vue"
      )
  },
  {
    path: "/loja",
    name: "store",
    meta: {
      title: "Loja - Minha Claro Residencial",
      requiresAuth: true,
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: () =>
      import(
        /* webpackChunkName: "store" */
        "../../pages/Store.vue"
      )
  },
  {
    path: "/loja/vitrine",
    name: "store",
    meta: {
      title: "Loja - Minha Claro Residencial",
      requiresAuth: true,
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: () =>
      import(
        /* webpackChunkName: "store-showroom" */
        "../../pages/StoreShowroom.vue"
      )
  },
  {
    path: "/loja/empresas",
    name: "store-enterprise",
    meta: {
      title: "Loja - Minha Claro Residencial",
      requiresAuth: true,
      isEnabledForNet: true,
      isEnabledForCtv: true
    },
    component: () =>
      import(
        /* webpackChunkName: "store-enterprise" */
        "../../pages/StoreEnterprise.vue"
      )
  },
  {
    path: "**",
    name: "not-found",
    meta: {
      isPublic: true,
      squad: "Global",
      title: "Página não encontrada - Minha Claro Residencial"
    },
    component: () =>
      import(
        /* webpackChunkName: "not-found" */
        "../../pages/NotFound.vue"
      )
  }
];
