<template>
  <fragment>
    <mcr-app-bar />
    <mcr-header
      v-if="headerProps.idmAccessToken"
      v-show="!isTotem"
      moreContractsPath="/selecao-de-contrato"
      accessibilityLink="/atendimento-acessivel"
      homeUrl="/"
      :gwDomain="headerProps.gwDomain"
      :gwToken="idmAccessToken"
      :logout="logout"
      :contrastFunc="setContrast"
      :beforeChangeContractFunc="generateTokenToContract"
      :selectedContract="headerProps.selectedContract"
      :filterMenu="filterMenu"
      :isCNPJ="isCNPJ"
      :menuClickEvent="checkIsAvailable"
      :modifyHref="modifyHref"
      @onChosenContract="headerProps.choosenContract"
      @onLoaded="headerProps.onHeaderLoaded"
      :extCustomerContracts="{ documentNumber: document, contractList }"
    ></mcr-header>
    <mcr-canceled-contract-bar />
    <main
      :class="
        this.$route.meta.hasBanner ? 'mdn-Container-fluid' : 'mdn-Container'
      "
      id="main-content"
    >
      <mcr-chat v-if="user && user.selectedContract" />
      <template v-if="
        (user && user.selectedContract && !isTotem) ||
        $route.path === '/selecao-de-contrato'
      ">
        <mcr-promo-copa-easter-egg/>
        <slot></slot>
        <redirect-modal />
      </template>
      <div class="loading-box" v-else>
        <mdn-spinner absolute />
      </div>
    </main>
    <template v-if="!isTotem">
      <mcr-consentiment-modal v-if="user && user.selectedContract" />
      <mdn-footer-simple
        v-if="user && !user.selectedContract"
      ></mdn-footer-simple>
      <mcr-footer
        v-if="user && user.selectedContract"
        :footerMenuList="footerMenuList"
      ></mcr-footer>
    </template>
  </fragment>
</template>

<script>
import { mapState, mapActions } from "vuex";
import McrFooter from "@minhaclarors/footer";
import HeaderModule from "@minhaclarors/header";

import UserIdentifier from "@/modules/user/identifier";
import MondrianUIModule from "@/modules/ui/mondrian.js";
import mdnFooterSimple from "../components/MdnFooterSimple";
import McrAppBar from "../components/McrAppBar.vue";
import McrCanceledContractBar from "../components/McrCanceledContractBar.vue";

import McrPromoCopaEasterEgg from "../components/McrPromoCopaEasterEgg.vue";

import McrConsentimentModal from "../components/McrConsentimentModal";

import MdnSpinner from '../components/MdnSpinner.vue';

import McrChat from "../components/McrChat.vue";

export default {
  mixins: [UserIdentifier],
  name: "private-layout",
  components: {
    McrHeader: HeaderModule.component,
    McrFooter,
    mdnFooterSimple,
    McrConsentimentModal,
    McrPromoCopaEasterEgg,
    McrAppBar,
    McrCanceledContractBar,
    MdnSpinner,
    McrChat
  },
  methods: {
    setContrast: MondrianUIModule.setContrast,
    ...mapActions("Auth", ["generateTokenToContract"]),
  },
  computed: {
    ...mapState("Auth", ["contractList", "document", "idmAccessToken"]),
  },
  data() {
    return {
      isTotem: window.localStorage.getItem("isTotem")
    };
  }
};
</script>

<style src="@minhaclarors/header/dist/mcr-header.css"></style>
<style lang="scss" scoped>
.mdn-Container-fluid {
  padding: 0;
}

.loading-box {
  position: relative;
  width: 100%;
  height: 500px;
}
</style>
